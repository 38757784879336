html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  word-wrap: break-word;
}

body {
  font-family: "Inter", "Noto Sans KR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafbfc;
  height: 100%;
}

html,
body,
body > div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

pre {
  font-family: "Inter", "Noto Sans KR";
  margin: 0px;
}

.sb-show-main {
  background-color: #fff;
}
